import { useEffect, useRef, useState } from "react"
import axios from 'axios'
import DailyIframe from "@daily-co/daily-js";
import './manageSession.css'
import io from 'socket.io-client'
import { ws } from '../../config'
import ManageBar from "./components/manageBar";

let socket = null
let role = null

function ManageSession({ userName, subDomain, roomName, apiKey }) {
  const videoRef = useRef(null);
  const [key, setKey] = useState(null)
  const [connectedUsers, setConnectedUsers] = useState({})

  useEffect(() => {
    const getKey = async () => {
      if (apiKey) {
        const options = {
          data: {
            properties: {
              room_name: roomName,
              is_owner: true,
              domain_id: "1c170a33-ea8f-400f-a672-8ab03aa318e0" // ID que se obtiene de la cuenta de Daily.co
            }
          },
          method: 'POST',
          url: 'https://api.daily.co/v1/meeting-tokens',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`
          }
        }
        try {
          const res = await axios.request(options)
          console.log(res.data.token);
          role = 1
          setKey(res.data.token)
        } catch (error) {
          role = 0
          setKey(' ')
          console.log(error);
        }
      } else {
        role = 0
        setKey(' ')
      }
    }
    if (!userName || !subDomain || !roomName) {
      return
    }
    getKey()// eslint-disable-next-line
  }, [])

  useEffect(() => {
    let newCallframe = null
    if (userName && subDomain && roomName && key) {

      const options = {
        showLeaveButton: true,
        showFullscreenButton: true,
        showLocalVideo: true,
        showParticipantsBar: false,
        userName: userName,
        url: `https://${subDomain}.daily.co/${roomName}${key.trim() !== '' ? '?t=' + key : ''}`
      }

      newCallframe = DailyIframe.createFrame(
        videoRef.current,
        options
      );
      newCallframe.join()

      if (!socket || (socket && !socket.connected)) {
        socket = io.connect(ws, { transports: ['websocket'] })
        socket.emit('userData', { userName: userName, subDomain: subDomain, roomName: roomName, apiKey: key })
      }

      socket.on('connectedUsers', (users) => {
        setConnectedUsers(users)
      })

      socket.on('getApiKey', apiKey => {
        setKey(apiKey)
      })
    }
    return () => {
      if (newCallframe) {
        newCallframe.destroy()
      }
      if (socket) {
        socket.off()
      }
    }// eslint-disable-next-line
  }, [key])

  if (!userName || !subDomain || !roomName) {
    return (
      <div className='callNull'>
        <h1>Algo salio mal</h1>
      </div>
    )
  }

  console.log(connectedUsers);

  return (
    <div className='callContainer'>
      <div className={role ? 'callFrame' : 'callFrame2'} ref={videoRef} />
      {role ?
        <ManageBar connectedUsers={connectedUsers} socket={socket} />
        : ''}
    </div >
  )
}

export default ManageSession
