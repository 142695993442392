import { useState } from 'react'
import "./ownSelect.css"

let OwnSelect = (props) => {
  const { selected: [selected, setSelected] } = { selected: useState(''), ...(props.state || {}) }
  const [dropDown, setDropDown] = useState(false)

  let list = [
    "Correo electrónico",
    "Whatsapp",
    "Llamada teléfonica",
    "Otro"
  ]

  let setSelectedOption = (evt) => {
    setSelected(evt.target.innerText)
    setDropDown(false)
  }

  return (
    <div className="selctCont">
      <p className="selected" value={selected} onClick={e => {setDropDown(true)}}>{selected || "¿Cómo se enteró del evento?"}</p>
      {
        dropDown &&
        <div className="dropCont">
          {
            list.map((ele, idx) => {
              return (
                <p key={idx} className="option" onClick={e => {setSelectedOption(e)}}>{ele}</p>
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default OwnSelect
