import { useState } from "react"

let warning = false
let status = false

let InputText = (props) => {
  const { text: [text, setText] } = { text: useState(''), ...(props.state || {}) }

  if (props.regex !== undefined) {
    let str = props.regex.split('_')[0]
    let regex = new RegExp(str, "g")

    if (props.className === props.regex.split('_')[1]) {
      status = true
    } else {
      status = false
    }

    if (!regex.test(text) && text.length > 0) {
      warning = true
    } else {
      warning = false
    }
  } else {
    status = false
  }

  return (
    <div className={"inputContainer " + props.className}>
      <input className={props.className} required type={props.type} id={props.id} placeholder={props.placeholder || "Campo por llenar"} value={text} onChange={e => {setText(e.target.value)}}/>
      {
        warning && status &&
        <p className={`warning ${props.theme}`}>{props.wrongText || "* Sin caracteres especiales"}</p>
      }
    </div>
  )
}

export default InputText
