import { useState } from 'react'
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import InputText from '../../../components/inputs/inputText'
import InputEmail from '../../../components/inputs/inputEmail'
import InputNumber from '../../../components/inputs/inputNumber'
import OwnSelect from '../../../components/select/ownSelect/ownSelect'
import CheckBox from '../../../components/inputs/checkBox/checkBox'
import { API } from '../../../config'

import './form.css'

let Form = (props) => {
  const cookies = new Cookies();

  const [userName, setUserName] = useState('')
  const [userCity, setUserCity] = useState('')
  const [email, setEmail] = useState('')
  const [emailVerify, setEmailVerify] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [business, setBusiness] = useState('')
  const [position, setPosition] = useState('')
  const [hearAbout, setHearAbout] = useState('')
  const [checkBox, setCheckBox] = useState(false)

  const [loanding, setLoanding] = useState(false)
  const [error, setError] = useState(false)
  const [msgError, setMsgError] = useState('')
  const [login, setLogin] = useState(false)

  let handleSubmit = (evt) => {
    evt.preventDefault()

    if (checkBox && hearAbout !== "") {
      setLoanding(true)

      let body = {
        name: userName,
        email: emailVerify.toLowerCase(),
        phone: userPhone,
        city: userCity,
        company: business,
        position: position,
        hearAbout: hearAbout,
      }

      let conf = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      };

      try {
        fetch(`${API}/register`, conf)
          .then(res => res.json())
          .then(res => {

            if (res.error) {
              if (res.error === "DuplicatedError") {
                setLoanding(false)
                setMsgError(`El correo ${emailVerify} ya esta registrado`)
                setError(true)
              }
            } else {
              let user = {
                userID: res._id,
                userName: res.name,
                userEmail: res.email,
                city: res.city,
                company: res.company,
                position: res.position,
              }

              cookies.set('registeredUser', JSON.stringify(user), { secure: true, path: '/', sameSite: true, maxAge: 43200 });
              window.location.href = "/login";
            }
          })
          .catch( evt => {
            setLoanding(false)
            setMsgError("Ups! Al parecer no hay conexión")
            setError(true)
          })
      } catch {
        setLoanding(false)
        setMsgError("Ups! Algo salió mal, pronto se solucionará")
        setError(true)
      }
    } else {
      if (hearAbout === "") {
        setMsgError("Cuéntanos cómo se enteró del evento")
        setError(true)
      } else {
        setMsgError("Acepta términos y condiciones")
        setError(true)
      }
    }
  }

  if (login) {
    return (
      <Redirect to="/login"/>
    )
  }

  return (
    <div className="formContainer">
      {
        !loanding
        ? !error
          ? <form className="form" onSubmit={e => {handleSubmit(e)}}>
              <InputText className="userName" type="text" id="userName" placeholder="Nombre y Apellido:" theme="light" regex="^[a-zA-Z ]*$_userName" state={{ text: [userName, setUserName] }}/>
              <InputText className="userCity" type="text" id="userCity" placeholder="Ciudad:" theme="light" state={{ text: [userCity, setUserCity] }}/>
              <InputEmail paste="false" className="userEmail" type="email" theme="light" wrongText="Correo incorrecto" placeholder="Correo electrónico:" state={{ email: [email, setEmail] }}/>
              <InputEmail paste="true" className="userEmail" type="email" theme="light" wrongText="Correo incorrecto" placeholder="Confirmar correo electrónico:" state={{ email: [emailVerify, setEmailVerify] }}/>
              <InputNumber className="userName" id="iserPhone"  placeholder="Teléfono:" state={{ number: [userPhone, setUserPhone] }}/>
              <InputText className="userBusiness" type="text" id="userBusiness" placeholder="Empresa:" theme="light" state={{ text: [business, setBusiness] }}/>
              <InputText className="userPosition" type="text" id="userPosition" placeholder="Cargo:" theme="light" state={{ text: [position, setPosition] }}/>
              <OwnSelect state={{ selected: [hearAbout, setHearAbout] }}/>

              <p className="redirect" onClick={e => {setLogin(true)}}>Ya estoy registrado</p>
              {
                email.toLowerCase() === emailVerify.toLowerCase()
                ? <button className="btnSubmit" type="submit">{props.sendText}</button>
                : <button disabled className="btnSubmit" type="submit">{props.sendText}</button>
              }
              <CheckBox state={{ tyc: [checkBox, setCheckBox] }} text="¿Autorizas y aceptas el tratamiento de datos por parte de nuestra organización?" />
            </form>
          : <div className="errorMsg">
              <h2>{msgError}</h2>
              {
                msgError !== "Gracias" &&
                <button className="back" onClick={e => {setError(false)}}>Volver</button>
              }
            </div>
        : <div className="loanding"></div>
      }
    </div>
  )
}

export default Form
