import { useEffect, useState } from 'react'

let interval = null

let Counter = (props) => {
  const { status: [open, setOpen] } = { status: useState(false), ...(props.state || {}) }
  const [counterValues, setCounterValues] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const [finishDate] = useState(new Date('May 27, 2021 15:00:00').getTime())

  useEffect(() => {
    interval = setInterval(() => updateCountdown(), 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const updateCountdown = () => {
    if (finishDate) {
      // Get the current time
      const currentTime = new Date().getTime();

      // Get the time remaining until the countdown date
      const distanceToDate = finishDate - currentTime;

      // Calculate days, hours, minutes and seconds remaining
      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      // For visual appeal, add a zero to each number that's only one digit
      const numbersToAddZeroTo = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      }

      if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      }

      if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      // Set the state to each new time
      setCounterValues({ days: days, hours: hours, minutes, seconds });

      if (parseInt(days) <= 0 && parseInt(hours) <= 0) {
        setOpen(true)
      } else {
        setOpen(false)
      }

      if (parseInt(days) <= 0 && parseInt(hours) <= 0 && parseInt(minutes) <= 0 && parseInt(seconds) <= 0) {
        clearInterval(interval)
        setOpen(true)
        setCounterValues({
          days: `00`,
          hours: `00`,
          minutes: `00`,
          seconds: `00`
        })
      }
    }
  }

  return (
    <div className="counterCont">
      <div className="contValue counterDays">
        <h2>Días</h2>
        <div className="valueCont">
          <h3>{counterValues.days}</h3>
        </div>
      </div>

      <div className="contValue counterHours">
        <h2>Horas</h2>
        <div className="valueCont">
          <h3>{counterValues.hours}</h3>
        </div>
      </div>

      <div className="contValue counterMinutes">
        <h2>Minutos</h2>
        <div className="valueCont">
          <h3>{counterValues.minutes}</h3>
        </div>
      </div>

      <div className="contValue counterSeconds">
        <h2>Segundos</h2>
        <div className="valueCont">
          <h3>{counterValues.seconds}</h3>
        </div>
      </div>
    </div>
  )
}

export default Counter
