import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie';
import Counter from '../../components/counter/counter'
import Login from '../login/login'
import Agenda from './agenda/agenda'
import { ws } from '../../config'
import io from "socket.io-client";

import Speakers from './speakers/speakers'
import './lobby.css'

const socket = io.connect(ws, { transports: ['websocket'] });

let Lobby = (props) => {
  let date = new Date()

  const cookies = new Cookies();
  const [room, setRoom] = useState(false)
  const [panel, setPanel] = useState("open") // close
  const [open, setOpen] = useState(false)

  let mayo13 = [
    {
      time: "3:15 p.m.",
      title: "Impacto, requisitos técnicos y transformación cultural del Laboratorio Clínico bajo la ISO/IEC 15189:2012, acreditación otorgada a Idime por la ONAC.",
      speakers: [
        {
          name: "Dra. Alba Cecilia Garzón González",
          position: "Directora Científica ACG Colombia"
        },
        {
          name: "Dra. Maribel Pinzón",
          position: "Directora de Calidad Laboratorio Clínico Idime S.A."
        },
      ]
    },
    {
      time: "3:55 p.m.",
      title: "Anticuerpos anti-espícula en SARS-CoV-2: evidencia de uso para evaluación post-vacuna al día de hoy",
      speakers: [
        {
          name: "Dra. Dennise Ríos Rincón",
          position: "Patóloga Clínica, Líder de Asuntos Médicos y Científicos Roche Diagnóstica Colombia"
        },
      ]
    },
    {
      time: "4:35 p.m.",
      subtitle: `“No se quede a medias, lucha contra Tuberculosis, el diagnóstico es la clave”`,
      title: "Tuberculosis: sensibilidad e identificación a través del medio líquido, nueva aplicación.",
      speakers: [
        {
          name: "Dra. Edilma Torrado",
          position: "Especialista Clinico BD. IDS"
        },
      ]
    },
  ]

  let user = cookies.get('sessionUser')

  useEffect(() => {

    if (user !== undefined) {
      document.title = "Idime | Lobby"

      socket.emit('onload', 'onload'); //onConnect
      socket.emit('new-user', {
        name: user.userName,
        uID: user.userID,
        page: `${user.userID}`
      });


      socket.on('user-list', (users) => {
        var countUID = 0;
        for (const userL in users) {
          if (users[userL].page === `${user.userID}`) {
            countUID++;
          }
        }

        if (countUID > 1) {
          console.log("Duplicado Lobby");
          cookies.remove('sessionUser')
          window.location.href = "/login";
        }
      });
    }


    return () => {
      socket.off()
      socket.close()
    }
  }, [])

  if (user === undefined) {
    window.location.href = "/";
    return (
      <Login />
    )
  }

  if (room) {
    window.location.href = "/room";
  }

  let logOut = (evt) => {
    cookies.remove('sessionUser')
    window.location.href = "/login";
  }

  return (
    <div className="lobbyContainer">
      <div className="menuCont">
        <button className="logout" onClick={e => {logOut(e)}}>Cerrar Sesión</button>
      </div>

      <div className="sections">
        <div className={`principal ${panel === 'close' ? "width" : "fit"}`}>
          <div className="eventName">
            <p>XXVI</p> <span>Jornada de Actualización en Laboratorio Clínico</span>
          </div>
          <Counter state={{ status: [open, setOpen] }}/>
          {
            open &&
            <button className="mainRoom" onClick={e => {setRoom(true)}}>Entrar</button>
          }
          <div className="logos">
            <div className="logoIdime"></div>
            <div className="logoAcreditacion"></div>
          </div>
        </div>

        <div className="dataCont">
          <div className={`arrow ${panel === "close" ? "left" : "rigth"}`} onClick={e => {setPanel(panel === "close" ? "open" : "close")}}></div>
          {
            panel === "open" &&
            <div className="container">
              <div className="agenda">
                <Agenda day="Conferencias" content={mayo13}/>
              </div>
              <div className="speakers">
                <h2 className="conferencistas">Conferencistas</h2>
                <Speakers user={user} />
              </div>
            </div>
          }
        </div>

        <div className="dataContRes">
          <div className={`arrow ${panel === "close" ? "left" : "rigth"}`} onClick={e => {setPanel(panel === "close" ? "open" : "close")}}>&#9654;</div>
          <div className="container">
            <div className="agenda">
              <Agenda day="Conferencias" content={mayo13}/>
            </div>
            <div className="speakers">
              <h2 className="conferencistas">Conferencistas</h2>
              <Speakers user={user} />
            </div>
          </div>
        </div>

        <div className="logosResponsive">
          <div className="logoIdime"></div>
          <div className="logoAcreditacion"></div>
        </div>

      </div>
    </div>
  )
}

export default Lobby
