import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Admin from './pages/admin/admin';
import Login from './pages/login/login'
import Register from './pages/register/register'
import Room from './pages/room/room'
import Lobby from './pages/lobby/lobby'
import Cover from './pages/cover/cover'

let Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Cover}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/registro" component={Register}/>
        <Route exact path="/room" component={Room}/>
        <Route exact path="/lobby" component={Lobby}/>
        <Route exact path="/admin" component={Admin}/>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes