import { useState } from 'react'
import './speakers.css'

import ImgSpeaker from '../../../img/lobby/imgSpeaker.png'
import Alba from '../../../img/lobby/AlbaC.png'
import Dennises from '../../../img/lobby/DennisesR.png'
import Edilma from '../../../img/lobby/EdilmaT.png'
import Maribel from '../../../img/lobby/MaribelP.png'

let Speakers = (props) => {
  const [popup, setPopup] = useState(false)

  let speakers = [
    {
      item: 1,
      speaker: "Dra. Alba Cecilia Garzón",
      subtitle: "Directora Científica ACG Colombia.",
      image: Alba,
      popup: {
        title: "Dra. Alba Cecilia Garzón",
        text: "Directora Científica de ACG Colombia, bacterióloga y bioanalista clínico de la Universidad Mayor de Cundinamarca, magister en seguridad clínica de la Universidad Internacional de la Rioja - España y especialista en sistemas de gestión de calidad y auditoria de servicios de salud de la Escuela de Medicina Juan N. Corpas. Docente de varias universidades en América Latina y Speaker Internacional."
      }
    },
    {
      item: 2,
      speaker: "Dra. Maribel Pinzón",
      subtitle: "Directora de Calidad Laboratorio Clínico Idime S.A.",
      image: Maribel,
      popup: {
        title: "Dra. Maribel Pinzón",
        text: "Directora de Calidad para el Laboratorio Clínico de Idime S.A., bacterióloga y laboratorista clínico egresada de la Universidad Colegio Mayor de Cundinamarca, especialista en epidemiología egresada de la Fundación Universitaria del Área Andina.  Cuenta con diversos diplomados y cursos en el sistema integral de gestión de calidad."
      }
    },
    {
      item: 3,
      speaker: "Dra. Dennise Ríos Rincón",
      subtitle: "Patóloga Clínica, Líder de Asuntos Médicos y Científicos",
      image: Dennises,
      popup: {
        title: "Dra. Dennise Ríos Rincón",
        text: "Gerente de Asuntos Médicos y Científicos de Roche Diagnóstica para Colombia, es médica y patóloga anatómica y clínica de la Universidad Nacional de Colombia. 11 años de experiencia en la coordinación médica de laboratorios de alto nivel de complejidad."
      }
    },
    {
      item: 4,
      speaker: "Dra. Edilma Torrado",
      subtitle: "Especialista Clínico BD. IDS",
      image: Edilma,
      popup: {
        title: "Dra. Edilma Torrado",
        text: "Especialista Clínico en Becton Dickinson Colombia, es bacterióloga egresada de la Pontificia Universidad Javeriana, amplio conocimiento en el área de microbiología. Docente universitario, ha escrito y participado en varias publicaciones sobre el laboratorio clínico."
      }
    },
  ]

  let popUpSp = (evt) => {
    evt.preventDefault()

    setPopup(evt.target.className.split(' ')[1])
  }

  let closePopUp = (evt) => {
    evt.preventDefault()

    if (evt.target.className !== "infoSp" && evt.target.className !== "textPop") {
      setPopup(false)
    }
  }

  return (
    <div className="speakerCont">
      {
        speakers.map((ele, idx) => {
          return (
            <div className="speaker" key={idx}>
              <div className={"image " + ele.item} style={{ backgroundImage: `url("${ele.image}")`}} onClick={e => {popUpSp(e)}}>
                <div className="plus"><p className={"plusText " + ele.item}>+</p></div>
              </div>
              <div className="info">
                <h2 className="name">{ele.speaker}</h2>
                <p className="desc">{ele.subtitle}</p>
              </div>

              {
                parseInt(popup) === ele.item &&
                <div className="modalCont" onClick={e => {closePopUp(e)}}>
                  <div className="infoSp">
                    <div className="close" onClick={e => {setPopup(false)}}>X</div>
                    <h2 className="textPop">{ele.popup.title}</h2>
                    <p className="textPop">{ele.popup.text}</p>
                  </div>
                </div>
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default Speakers