import React, { useState, useEffect } from "react";
import { API, ws } from '../../config'
import io from "socket.io-client";

const socket = io.connect(ws, { transports: ['websocket'] });

let Stream = (props) => {
  const [iframeSocket, setIframeSocket] = useState(null)
  const [iframeDB, setIframeDB] = useState(null)
  const [zoomBtn, setZoomBtn] = useState(false)
  const [urlBtn, setUrlBtn] = useState('')


  useEffect(() => {

    socket.on("zoomBtn", (data) => {
      setZoomBtn(data.status)
      setUrlBtn(data.url)
    });

    let confZoom = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    };

    fetch(`${API}/settings/zoomBtn`, confZoom)
      .then(res => res.json())
      .then(res => {

        let data = {
          status: res.settings.split(' ')[2].replace(/,/g, ""),
          url: res.settings.split(' ')[4]
        }

        setZoomBtn(data.status === 'true')
        setUrlBtn(data.url)
      })


    /* =============================================== */

    socket.on("streaming", data => {
      setIframeSocket(data.msg);
    });

    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    };

    fetch(`${API}/iframe/iframeDB`, conf)
      .then(res => res.json())
      .then(res => {
        setIframeDB(res.iframe)
      })

      return () => {
        socket.off()
      }
  }, []);

  return (
    <React.Fragment>
      {
        iframeSocket
        ? <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframeSocket}}></div>
        : <div className="iframeCont" dangerouslySetInnerHTML={{__html: iframeDB}}></div>
      }
      {
        zoomBtn &&
        <a className="zoomBtn" href={urlBtn} target="_blank" rel="noopener noreferrer">
          <div className="imgZoom"></div>
          <p>Unirse a Zoom</p>
        </a>
      }
    </React.Fragment>
  )
}

export default Stream;
