import { useState, useEffect } from 'react'
import { API, ws } from '../../../config'
import Cookies from 'universal-cookie';
import io from "socket.io-client";

const socket = io.connect(ws, { transports: ['websocket'] });

let Online = (props) => {
  const cookies = new Cookies();
  let user = cookies.get('sessionAdmin')

  const [online, setOnline] = useState([])
  const [specific, setSpecific] = useState({})
  const [consult, setConsult] = useState('')

  useEffect(() => {
    socket.emit("new-user", 'adminload');

    socket.on("user-list", (users) => {
      if (user !== undefined) {
        let listUsers= []
        for (const user in users) {
          listUsers.push(users[user])
        }
        setOnline(listUsers)
      }
    });

    let intervalSave = setInterval(() => {

      fetch(`${API}/connected/redis/save`)
      .then(res => res.json())
      .then(res => {
        console.log(res);
      })

    }, 900000);

    return () => {
      socket.off()

      clearInterval(intervalSave)
    }
  }, [])

  let handleConsult = (evt) => {
    evt.preventDefault()

    fetch(`${API}/register/${consult}`)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setSpecific(res)
      })
  }

  return (
    <div className="listCont">
      <div className="action">
        {
          online.length === 1
            ? <h3>{`${online.length} Usuario online`}</h3>
            : <h3>{`${online.length} Usuarios online`}</h3>
        }
        <form onSubmit={e => { handleConsult(e) }}>
          <input type="text" value={consult} onChange={e => {setConsult(e.target.value)}}/>
          <button type="submit" className='adminButton'>Consultar</button>
        </form>
      </div>
      <div className="listRegisters">
        {
          online.map((ele, idx) => {
            return (
              <div className="userInfo" key={idx}>
                <p>{ele.name}</p>
                <p>{ele.uID}</p>
              </div>
            )
          })
        }
      </div>
      {
        specific.name &&
        <div className="especificUser">
          <p>{specific.name}</p>
          <p>{specific._id}</p>
          <p>{specific.email}</p>
          <p>{specific.phone}</p>
          <p>{specific.city}</p>
        </div>
      }
    </div>
  )
}

export default Online
