import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import Form from './form/form'

import './login.css'

let Login = (props) => {
  let phone = "3023180804"
  let country = "+57"
  let urlHelp = `https://wa.me/${country}${phone}`

  const [register, setRegister] = useState(false)

  useEffect(() => {

    document.title = "Idime | Log In"

    return () => {
      // UnMount
    }
  }, [])

  if (register) {
    return (
      <Redirect to="/registro"/>
    )
  }

  return (
    <div className="loginContainer">
      <div className="container" >
        <div className="forms">
          <div className="logos">
            <div className="logoIdime"></div>
            <div className="logoAcreditacion"></div>
          </div>
          <div className="eventName">
            <p>XXVI</p> <span>Jornada de Actualización en Laboratorio Clínico</span>
          </div>
          <Form sendText="Ingresar"/>
          <p className="question">¿No tienes un usuario?</p>
          <button className="registerRedirect" onClick={e => {setRegister(true)}}>Regístrate</button>
        </div>
      </div>
      <a className="soporte" href={urlHelp} target="_blank" rel="noopener noreferrer"></a>
    </div>
  )
}

export default Login
