import { useState, useEffect } from 'react'
import { API, ws } from '../../config'
import IframeMan from '../../components/stream/iframeMan'
import Online from './onlineUsers/onlineUsers'
import TriviaAdmin from '../../components/trivia/triviaAdmin2'
import SlideCheck from '../../components/inputs/slideCheck/slideCheck'
import UploadFileImg from '../../components/uploadFile/uploadFileImg'
import Cookies from 'universal-cookie';

import io from "socket.io-client";

import './admin.css'
import InputText from '../../components/inputs/inputText'

const socket = io.connect(ws, { transports: ['websocket'] });

let Admin = ({ history }) => {
  const cookies = new Cookies();
  const [registers, setRegisters] = useState([])
  const [zoomBtn, setZoomBtn] = useState(false)
  const [url, setUrl] = useState('https://zoom.us/')

  let user = cookies.get('sessionAdmin')

  if (user === undefined) {
    window.location.href = "/";
  }

  useEffect(() => {
    // User Connection and Disconnection
    socket.on("user-connected", (user) => {
      console.log(`${user.name} ha entrado a la sala`);
    });

    socket.on("user-disconnected", (user) => {
      if (user !== null) {
        console.log(`Usuario ${user.name} se ha desconectado`);
      }
    });

    return () => {
      socket.off()
      socket.close()
    }

  }, [])

  /* =================================================== */
  /* ===========USUARIOS REGISTRADOS =================== */

  let handleClickRegisters = (evt) => {
    let conf = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
    }

    fetch(`${API}/register/`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);
        setRegisters(res)
      })
  }

  /* ===========USUARIOS REGISTRADOS =================== */
  /* =================================================== */

  useEffect(() => {

    let conf = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ settings: `{ status: ${zoomBtn}, url: ${url} }` })
    }

    fetch(`${API}/settings/zoomBtn`, conf)
      .then(res => res.json())
      .then(res => {
        if (url !== "") {
          socket.emit('zoomBtn', {
            url: url,
            status: zoomBtn,
          })
        }
      })

  }, [zoomBtn])


  const logOut = () => {
    cookies.remove('sessionAdmin')
    window.location.href = "/";
  }

  return (
    <div className="adminContainer">
      <div className="backCont">
        <button className='backBtn close' onClick={logOut}>
          <div className="arrow-left">
            &#10008;
          </div>
          <p>Cerrar Sesión</p>
        </button>
      </div>

      <div className="card Zoom">
        <div className="title">
          <h2>Kahoot</h2>
          <InputText type="text" placeholder="Ingrese la url" value={url} state={{ text: [url, setUrl] }}/>
          {
            url !== "" &&
            <SlideCheck state={{ slideCheck: [zoomBtn, setZoomBtn] }} />
          }
        </div>
      </div>

      <div className="card onlineCont">
        <div className="title">
          <h2>Usuarios online</h2>
        </div>
        <Online socket={socket}/>
      </div>

      <div className="card gamesCont">
        <div className="title">
          <h2>Actividades</h2>
        </div>
        <TriviaAdmin socket={socket} />
      </div>

      <div className="card registers">
        <div className="title">
          <h2>Usuarios registrados</h2>
        </div>
        <div className="listCont">
          <div className="action">
            {
              registers.length === 1
                ? <h3>{`${registers.length} Usuario registrado`}</h3>
                : <h3>{`${registers.length} Usuarios registrados`}</h3>
            }
            <button onClick={e => { handleClickRegisters(e) }} className='adminButton'>Cargar lista</button>
          </div>
          <div className="listRegisters">
            {
              registers.map((ele) => {
                return (
                  <div className="userInfo" key={ele._id}>
                    <p>{ele.name}</p>
                    <p>{ele._id}</p>
                    <p>{ele.email}</p>
                    <p>{ele.phone}</p>
                    <p>{ele.city}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="card agendaMan">
        <div className="title">
          <h2>Agenda</h2>
        </div>
        <div className="uploadimages">
          <UploadFileImg imageTitle="imgDia1" id="imgDia1" text="Selecciona Imagen dia 1"/>
          <UploadFileImg imageTitle="imgDia2" id="imgDia2" text="Selecciona Imagen dia 2"/>
        </div>
      </div>

      <div className="card streaming">
        <IframeMan socket={socket}/>
      </div>

    </div>
  )
}

export default Admin
