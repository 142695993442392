import { useState } from "react"

import Politics from '../../../media/PoliticaIDIME.pdf'

import './checkBox.css'

let CheckBox = (props) => {
  const { tyc: [tyc, setTyc] } = { tyc: useState(''), ...(props.state || {}) }
  const [modal, setModal] = useState(false)

  let closeAnyWay = (evt) => {
    if (evt.className === "ModalCont") {
      setModal(false)
    }
  }

  return (
    <div className="checkBoxContainer">
      <label className="customCheckBox" htmlFor={props.id} >
        <div className="checkCont" onClick={e => {setTyc(!tyc)}}>
          {
            tyc ? <span className="check ok">&#10004;</span> : <span className="check not">	&#10008;</span>
          }
        </div>
        <div className="textCont">
          <p>
            {props.text} <span style={{ color: "#00AFAA" }} onClick={e => {setModal(!modal)}}>Para conocer más haz clic aquí.</span>
          </p>
        </div>
      </label>

      {
        modal &&
        <div className="ModalCont" onClick={e => {closeAnyWay(e.target)}}>
          <div className="close" onClick={e => {setModal(false)}}>X</div>
          <div className="modalstyle">
            <div className="modalInner">
              <iframe src={Politics} frameborder="0"></iframe>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default CheckBox
