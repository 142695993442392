import React, { useState, useEffect } from 'react'
import io from "socket.io-client";
import { API } from '../../config'


const IframeMan = ({ socket }) => {
  const [iframe, setIframe] = useState({})
  const [textJs, setTextJs] = useState('')
  const [textHtml, setTextHtml] = useState('')
  const [iframClient, setIframeClien] = useState('')

  let count = 0

  const sendIframe = (iframe) => {
    socket.emit('streaming', { msg: iframe })
    count = 0
  }

  socket.on('streaming', data => {
    console.log(data);
    setIframeClien(data.msg)
  })

  useEffect(() => {

    if (textJs && textJs[1] === 's') {
      let iframeSplit = ''
      let iframeFinal = {}
      try {
        iframeSplit = textJs.split('(')
        iframeSplit.pop()
        iframeSplit = iframeSplit[iframeSplit.length - 1]
        iframeSplit = iframeSplit.split(',')

        let regexS = /'|"/gi;

        let p = iframeSplit[0].replace(regexS, '');
        let c = iframeSplit[1].replace(regexS, '');
        let q = iframeSplit[2].split('+')[0].replace(regexS, '');

        c = c.replace(/ /gi, '')

        iframeFinal = {
          p: p,
          c: c,
          q: q
        }

      }
      catch (err) {
        console.log(err);
      }
      setIframe(iframeFinal)
    }

    return () => { }
  }, [textJs, textHtml])


  let handleClickSendJs = (evt) => {
    let id = iframe.p + iframe.c

    let iframeJS = `<iframe id=${id || ''} src="https://boxcast.tv/view-embed/${iframe.c}?${iframe.q}" frameBorder={0} allowFullScreen={true} allow='autoplay; encrypted-media'></iframe>`
    let conf = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        iframe: iframeJS,
        name: "iframeDB"
      }),
    };

    fetch(`${API}/iframe/iframeDB`, conf)
      .then(res => res.json())
      .then(res => {
        count = 0
        count++
        console.log(count);
        if (count === 1) {
          sendIframe(res.iframe)
        }
        setTextHtml('')
        setTextJs('')
      })
  }

  let handleClickSendHtml = (evt) => {
    let conf = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        iframe: textHtml,
        name: 'iframeDB'
      }),
    };

    fetch(`${API}/iframe/iframeDB`, conf)
      .then(res => res.json())
      .then(res => {
        count = 0
        count++
        if (count === 1) {
          sendIframe(res.iframe)
        }
        setTextJs('')
        setTextHtml('')
      })
  }

  return (
    <React.Fragment>
      <div className="title">
        <h2>Streamings</h2>
      </div>

      <div className="input">
        <input value={textHtml} type="text" id="iframeHtml" placeholder='Ej: <iframe src="https://www.company.com" allowfullscreen></iframe>' onChange={e => { setTextHtml(e.target.value) }} />
      </div>

      <div className="input">
        {iframe === 'error' && <h2>Ingresa un codigo valido</h2>}
        <input value={textJs} type="text" id="iframeJs" placeholder='Ingrese el codigo javascript' onChange={e => { setTextJs(e.target.value) }} />
      </div>

      <div className="frame">
        {
          textHtml
            ? <div dangerouslySetInnerHTML={{ __html: textHtml }}></div>
            : <iframe id={iframe.p + iframe.c || ""} src={`https://boxcast.tv/view-embed/${iframe.c}?${iframe.q}`} frameBorder="0" scrolling="auto" allow="autoplay; fullscreen"></iframe>
        }
      </div>
      {
        iframClient &&
        <div className="userStream" dangerouslySetInnerHTML={{ __html: iframClient }}></div>
      }

      <div className="actions">
        <button className="btnActions sendHtml" onClick={e => { handleClickSendJs(e) }}>Enviar Js</button>
        <button className="btnActions sendJs" onClick={e => { handleClickSendHtml(e) }}>Enviar html</button>
      </div>
    </React.Fragment>
  )
}

export default IframeMan
