import Swal from 'sweetalert2'


export default function success(msg, bool) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,/* 
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    } */
  })

  if (bool) {
    Toast.fire({
      icon: 'success',
      title: msg
    })
  } else {
    Toast.fire({
      icon: 'error',
      title: msg
    })
  }
}