import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import Stream from '../../components/stream/stream'
import Chat from '../../components/chat/chat'
import TriviaUser from '../../components/trivia/triviaUser';
import Cookies from 'universal-cookie';
import { ws } from '../../config'
import io from "socket.io-client";
import ManageSession from "../../components/manageSession/manageSession"


import './room.css'

let socket = null

let Room = (props) => {
  const cookies = new Cookies();

  const [back, setBack] = useState(false)
  const [clickedChat, setClickedChat] = useState('')
  const [clickedAgenda, setClickedAgenda] = useState('')
  const [clickedBack, setClickedBack] = useState('')
  const [clickedClose, setClickedClose] = useState('')
  const [clickedGame, setClickedGame] = useState('')
  const [logout, setLogout] = useState(false)
  const [menu, setMenu] = useState(false)
  const [menuOption, setMenuOption] = useState(false)
  const [componentM, setComponenteM] = useState(null)
  const [triviaStatus, setTriviaStatus] = useState(false)
  const [send, setSend] = useState(false)

  let user = cookies.get('sessionUser')

  let agenda = [
    { time: "3:00 p.m.", title: "Bienvenida." },
    { time: "3:05 p.m.", title: "Palabras de bienvenida." },
    { time: "3:10 p.m.", title: "Video institucional laboratorio clínico de referencia." },
    { time: "3:15 p.m.", title: "1era conferencia: Impacto, requisitos técnicos y transformación cultural del Laboratorio Clínico bajo la ISO/IEC 15189:2012, acreditación otorgada a Idime por la ONAC." },
    { time: "3:45 p.m.", title: "Ronda de preguntas primera ponencia." },
    { time: "3:50 p.m.", title: "Sorteo 1." },
    { time: "3:55 p.m.", title: "2da conferencia: Anticuerpos anti-espícula en SARS-CoV-2: evidencia de uso para evaluación post-vacuna al día de hoy" },
    { time: "4:25 p.m.", title: "Ronda de preguntas segunda ponencia." },
    { time: "4:30 p.m.", title: "Sorteo 2." },
    { time: "4:35 p.m.", title: '3era conferencia: “No se quede a medias, lucha contra Tuberculosis, el diagnóstico es la clave” Tuberculosis: sensibilidad e identificación a través del medio líquido, nueva aplicación.' },
    { time: "5:05 p.m.", title: "Ronda de preguntas tercera ponencia." },
    { time: "5:10 p.m.", title: "Sorteo 3." },
    { time: "5:15 p.m.", title: "Palabras de despedida." },
    { time: "5:20 p.m.", title: "Cierre." },
  ]

  useEffect(() => {

    if (user !== undefined) {
      document.title = "Idime | Room"

      if (!socket || (socket && !socket.connected)) {
        socket = io.connect(ws, { transports: ['websocket'] })
        socket.emit('new-user', { name: `${user ? user.name : 'Generic user'}`, uID: `${user ? user._id : 'Generic ID'}`, page: "room" })
        setTimeout(() => {
          setSend(true)
        }, 10000);
      }
    }

    return () => {
      if (socket) {
        socket.off()
        socket.disconnect()
      }
    }
  }, [])

  useEffect(() => {

    if (socket) {
      socket.on("connect", (data) => {
        if (send) {
          socket.emit('new-user', { name: `${user ? user.name : 'Generic user'}`, uID: `${user ? user._id : 'Generic ID'}`, page: "room" })
        }
      })

      socket.on('user-list', (users) => {
        var countUID = 0;
        for (const userL in users) {
          if (users[userL].page === `${user.userID}`) {
            countUID++;
          }
        }

        if (countUID > 1) {
          console.log("Duplicado Room");
          cookies.remove('sessionUser')
          window.location.href = "/login";
        }
      });
    }

  }, [send, menu])

  if (user === undefined) {
    return (
      <Redirect to="/login" />
    )
  }


  let clickedFun = (evt) => {
    let classSelected = evt.target.className.split('-')[1].split(' ')[0]

    setClickedChat('')
    setClickedAgenda('')
    setClickedClose('')
    setClickedBack('')
    setClickedGame('')

    setMenuOption(true)
    setComponenteM(classSelected)

    if (classSelected === "chat") {
      setClickedChat(`clicked-${classSelected}`)
    } else if (classSelected === "agenda") {
      setClickedAgenda(`clicked-${classSelected}`)
    } else if (classSelected === "game") {
      setClickedGame(`clicked-${classSelected}`)
    } else if (classSelected === "back") {
      setClickedBack(`clicked-${classSelected}`)
      setBack(true)
    } else if (classSelected === "close") {
      setLogout(true)
      setClickedClose(`clicked-${classSelected}`)
    }
  }

  let cleanClickedFun = (evt) => {
    setClickedChat('')
    setClickedAgenda('')
    setClickedClose('')
    setClickedBack('')

    setMenuOption(false)
  }


  /* ========================================================== */

  if (back) {
    return (
      <Redirect to="/lobby" />
    )
  }

  if (logout) {
    cookies.remove('sessionUser')
    socket.off()
    return (
      <Redirect to="/login" />
    )
  }

  return (
    <div className="roomContainer">
      <div className="sections">
        <div className="hamburgerCont">
          <div className={menu ? 'equis' : 'hamburger'} onClick={e => { setMenu(!menu) }}></div>
        </div>

        <div className={`desplegable-${menu ? 'open' : 'close'}`}>
          <div className="menu">
            <div className={`btnCont-chat ${clickedChat}`} onClick={e => { clickedFun(e) }}>
              <div className="icon-chat chat"></div>
              <button className="btn-chat">Chat</button>
            </div>

            <div className={`btnCont-agenda ${clickedAgenda}`} onClick={e => { clickedFun(e) }}>
              <div className="icon-agenda agenda"></div>
              <button className="btn-agenda">Ver agenda</button>
            </div>

            <div className={`btnCont-game ${clickedGame}`} onClick={e => { clickedFun(e) }}>
              <div className="icon-game game"></div>
              <button className="btn-game">Sorteos</button>
            </div>

            <div className={`btnCont-back ${clickedBack}`} onClick={e => { clickedFun(e) }}>
              <div className="icon-back back"></div>
              <button className="btn-back">Regresar</button>
            </div>

            <div className={`btnCont-close ${clickedClose}`} onClick={e => { clickedFun(e) }}>
              <div className="icon-close close"></div>
              <button className="btn-close">Cerrar sesión</button>
            </div>
          </div>
          <div className={`menuOptionCont-${menuOption ? "open" : "close"}`}>
            <div className="title" onClick={e => { cleanClickedFun(e) }}>
              <div className="iconCloseM"></div>
              <h2>{componentM === 'chat' ? "Chat Público" : componentM === 'game' ? "Sorteos" : "Agenda"}</h2>
            </div>
            <div className="content">
              <div className={`agendaCont-${componentM}`}>
                {
                  agenda.map((ele, idx) => {
                    return (
                      <p className="item" key={idx}><span>{ele.time}</span> {ele.title}</p>
                    )
                  })
                }
              </div>
              {
                socket &&
                <Chat className={componentM} socket={socket} user={user} />
              }
              <div className={`gameCont-${componentM}`}>
                {/* <TriviaUser userID={user.userID || "Invitado"} state={{ triviaStatus: [triviaStatus, setTriviaStatus] }} /> */}
              </div>
            </div>
          </div>
        </div>

        <div className={`stream ${menuOption ? "little" : "big"}`}>
          <div className="logos">
            <div className="idime"></div>
            <div className="evName"></div>
          </div>
          <div className="player">
            <ManageSession
              userName={user.userName}
              subDomain={'ozelProducciones'}
              roomName={'test'}
              apiKey={user.userEmail === 'admin@ozelproducciones.com' ? '1bec25f2042fedcab55efca0c1d61e57f27923ffe783579938d6dad2a76ffe09' : ''}
              socket={socket}
            />
            <h2>En Idime S.A contamos con acreditación ONAC, vigente a la fecha, con código de acreditación 20-LCL-001, bajo la norma ISO15189:2012</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Room
