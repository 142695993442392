import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import Counter from '../../components/counter/counter'

import './cover.css'

let Cover = (props) => {
  const [login, setLogin] = useState(false)
  const [register, setRegister] = useState(false)


  useEffect(() => {

    document.title = "Idime | Cover"

    return () => {
      // UnMount
    }
  }, [])

  if (login) {
    return (
      <Redirect to="/login"/>
    )
  }

  if (register) {
    return (
      <Redirect to="/registro"/>
    )
  }

  return (
    <div className="coverCont">
      <div className="infoCont">
        <div className="title">
          <h2>¡Seguimos <span className="comp">comprometidos</span><br /> con nuestros <br /> <span className="strong">Laboratorios de Referencia!</span></h2>
        </div>

        <div className="eventName">
          <p>XXVI</p> <span>Jornada de Actualización en Laboratorio Clínico</span>
        </div>

        <Counter />

        <div className="btnCont">
          <button className="login" onClick={e => {setLogin(true)}}>Ingresar</button>
          <button className="register" onClick={e => {setRegister(true)}}>Regístrate</button>
        </div>

        <div className="logos">
          <div className="logoIdime"></div>
          <div className="logoAcreditacion"></div>
        </div>

        <div className="infoAcredit">
          <p>En Idime S.A contamos con acreditación ONAC, vigente a la fecha, con código de acreditación 20-LCL-001, bajo la norma ISO15189:2012</p>
        </div>

        <div className="social">
          <div className="media">
            <a href="https://idime.com.co/" target="_blank" rel="noopener noreferrer"><p className="web">www.idime.com.co</p></a>
            <a href="https://www.facebook.com/IdimeSA" target="_blank" rel="noopener noreferrer"><div className="socialMedia"><div className="icon face"></div><p>/IdimeSA</p></div></a>
            <a href="https://twitter.com/Idimesa" target="_blank" rel="noopener noreferrer"><div className="socialMedia"><div className="icon twitter"></div><p>/IdimeSA</p></div></a>
            <a href="https://www.linkedin.com/company/idime/" target="_blank" rel="noopener noreferrer"><div className="socialMedia"><div className="icon linkedin"></div><p>/Idime</p></div></a>
          </div>
          <p className="subTitle">Porque tu salud y la de tu familia merecen toda nuestra atención</p>
        </div>
      </div>
    </div>
  )
}

export default Cover
