import { useState } from 'react'
import Cookies from 'universal-cookie';
import InputEmail from '../../../components/inputs/inputEmail'
import { API } from '../../../config'

import './form.css'

let Form = (props) => {
  const cookies = new Cookies();

  const [email, setEmail] = useState('')
  const [loanding, setLoanding] = useState(false)
  const [error, setError] = useState(false)
  const [msgError, setMsgError] = useState('')

  let handleSubmit = (evt) => {
    evt.preventDefault()

    setLoanding(true)

    let body = {
      user: email.toLowerCase(),
      password: ""
    }

    let conf = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    try {
      fetch(`${API}/auth/login`, conf)
        .then(res => res.json())
        .then(res => {

          if (res.error) {
            if (res.error === `Not found ${email}`) {
              setLoanding(false)
              setMsgError(`El correo ${email} no está registrado`)
              setError(true)
            } else if (res.error === "Wrong Password") {
              setLoanding(false)
              setMsgError(`La contraseña es incorrecta`)
              setError(true)
            }
          } else if (res.jwt !== undefined) {

            let user = {
              userID: res.data.user._id,
              userName: res.data.user.name,
              userEmail: res.data.user.email,
              userJWT: res.jwt,
            }

            if (res.data.user.email === process.env.REACT_APP_USER_CLIEN_ADMIN_ACCESS) {
              cookies.set('sessionAdmin', JSON.stringify(user), { secure: false, path: '/', sameSite: true, maxAge: 259200 }); //secure: true
              window.location.href = "/admin";
            } else {
              cookies.set('sessionUser', JSON.stringify(user), { secure: false, path: '/', sameSite: true, maxAge: 259200 }); // secure: true
              window.location.href = "/lobby";
            }
          } else {
            setLoanding(false)
            setMsgError("Ups! Algo salió mal, pronto se solucionará")
            setError(true)
          }
        })
        .catch( evt => {
          setLoanding(false)
          setMsgError("Ups! Al parecer no hay conexión")
          setError(true)
        })
    } catch {
      setLoanding(false)
      setMsgError("Ups! Algo salió mal, pronto se solucionará")
      setError(true)
    }
  }

  return (
    <div className="formContainer">
      {
        !loanding
        ? !error
          ? <form className="form" onSubmit={e => {handleSubmit(e)}}>
              <InputEmail className="userEmail" type="email" theme="light" wrongText="Correo incorrecto" placeholder="Correo electrónico:" state={{ email: [email, setEmail] }}/>
              <button className="btnSubmit" type="submit">{props.sendText}</button>
            </form>
          : <div className="errorMsg">
              <h2>{msgError}</h2>
              {
                msgError !== "Gracias" &&
                <button className="back" onClick={e => {setError(false)}}>Volver</button>
              }
            </div>
        : <div className="loanding"></div>
      }
    </div>
  )
}

export default Form
