import React, { useState, useEffect } from 'react'
import io from "socket.io-client";
import Chart from 'chart.js'
import { API, ws } from '../../config'
import './triviaUser.css'

const socket = io.connect(ws, { transports: ['websocket'] });

const TriviaUser = ({ userID, ...rest }) => {

  const { triviaStatus: [triviaStatus, setTriviaStatus] } = { triviaStatus: useState(false), ...(rest.state || {})}
  const [state, setState] = useState(0)
  const [trivia, setTrivia] = useState({})
  const [triviaID, setTriviaID] = useState('')
  const [numOfQuestions, setNumOfQuestions] = useState(0)
  const [listOfWinners, setListOfWinners] = useState([])
  const [optionsPoll, setOptionsPoll] = useState([])

  let stopStatus = false
  var labels = [];
  var votes = [];
  let chart

  let updatingChart = (Lab, Vot) => {
    let ctx = document.getElementById('myChart')

    /* DRAWING CHART */
    if (ctx) {

      ctx.getContext('2d');
      chart = new Chart(ctx, {
        // The type of chart we want to create
        type: 'bar',

        // The data for our dataset
        data: {
          labels: Lab,
          datasets: [{
            label: 'Votos',
            backgroundColor: '#00AFAA',
            borderColor: '#00AFAA',
            data: Vot,
          }]
        },
        // Configuration options go here
        options: {
          aspectRatio: 1.77777,
          title: {
            display: false,
          },
          scales: {
            yAxes: [{
              gridLines: {
                color: '#CCC',
              },
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                min: 0,
                suggestedMax: 4,
                color: '#EEE',
              }
            }],
            xAxes: [{
              gridLines: {
                color: '#EEE',
              },
            }],
          },
          legend: {
            display: true,
          },
          events: []
        }
      });
    }
  }

  useEffect(() => {
    socket.on("start-game", nQuestions => {
      setState(1)
      setTriviaStatus(true)
      setNumOfQuestions(nQuestions)
    });

    socket.on("trivia-question", (id, trivia) => {
      setState(2)
      setTrivia(trivia)
      setTriviaID(id)
    });

    socket.on('countdown', (data, id, trivia, time) => {
      const display = document.getElementById('counterR');
      const duration = time;
      if (data === 'start') {
        startTimer(duration, display, id, trivia)
      }
    })

    socket.on('gold', (data) => {
      stopStatus = true
      setListOfWinners(data.results)
      if (data.gameSelect) {
        setState(5)
      } else {
        let conf = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        };

        fetch(`${API}/trivia/answer/${data.triviaID}/${data.questionID}`, conf)
          .then(res => res.json())
          .then(ansData => {
            // Get count for each choice
            let listOptions = []
            let graphObj = []

            console.log(ansData);
            console.log(trivia.choices);

            trivia.choices.map((ele) => {
              graphObj.push({
                name: ele.text,
                ansID: ele._id,
                quantity: 0
              })
            })

            graphObj.map((answer) => {
              ansData.map((option) => {
                if (option.choiceId === answer.ansID) {
                  answer.quantity++
                }
              })
            })

            console.log(graphObj);

            graphObj.map((ele, idx) => {
              labels.push(`Opción-${idx + 1}`)
              votes.push(ele.quantity)
              listOptions.push(ele.name)
            })

            setOptionsPoll(listOptions)

            updatingChart(labels, votes)
            labels = [];
            votes = [];
          })

        setState(6)
      }
    });

    socket.on('cancel-trivia', cancel => {
      setListOfWinners([])
      setState(0)
      setNumOfQuestions(0)
      setTrivia({})
      setTriviaID('')
      setTriviaStatus(false)
    });

    return () => {
      socket.off()
    }
  }, [trivia]);

  let startTimer = (duration, display, id, trivia) => {
    var timer = duration, minutes, seconds;
    var inter = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer === 0) {
          console.log('Time over');
          timer = 0;
          clearInterval(inter);

          if (!stopStatus) {
            console.log("Automatic Stop");
            setTimeout(() => {
              sendAnswer("empty", "empty")
            }, 1000);
          }
        }
      }, 1000);
    }

  const sendAnswer = (choiceId, choiceText) => {

    if (choiceId !== "empty" && choiceText !== "empty") {
      const data = {
        triviaId: triviaID,
        questionId: trivia._id,
        choiceId: choiceId,
        userId: userID,
        choiceText: choiceText,
      };

      socket.emit("trivia-answer", data);
      socket.emit('standby', 'Espera a que el tiempo termine');
    }

    if (numOfQuestions === 1) {
      setState(4)
    } else {
      setNumOfQuestions(numOfQuestions - 1)
      setState(3)
    }
  }

  if (state === 0) return (<div></div>)

  if (state === 1) return (
    <div className='triviaUser1'>
      <div className="triviaCont">
        <h1>Prepárate, pronto comenzará esta actividad</h1>
      </div>
    </div>
  )

  if (state === 2) return (
    <div className='triviaUser2'>
      <div className="triviaCont">
        <div className='triviaTimeAndQuestion'>
          <h3 id='counterR'>00:00</h3>
          <h2>{trivia.text}</h2>
        </div>
        <div className='triviaAnswers'>
          <div className="answersCont">
            {trivia.choices ?
              trivia.choices.map((choice, i) => (
                <button key={i} className='triviaAnswer' onClick={() => sendAnswer(choice._id, choice.text)}>{choice.text}</button>
              )) : ''
            }
          </div>
        </div>
      </div>
    </div>
  )

  if (state === 3) return (
    <div className='triviaUser1'>
      <div className="triviaCont">
        <h1>Pronto comenzará la siguiente pregunta, prepárate</h1>
      </div>
    </div>
  )

  if (state === 4) return (
    <div className='triviaUser1'>
      <div className="triviaCont">
        <h1>Espera por los resultados</h1>
      </div>
    </div>
  )

  if (state === 5) return (
    <div className='triviaUser5'>
      <div className="triviaCont">
        <h2>Lista de ganadores</h2>
        {
          (listOfWinners.length > 0)
          ? listOfWinners.map((winner, i) => {
              let date = Date.parse(winner.user.createdAt)
              let day = Math.floor(date / (1000 * 60 * 60 * 24))
              let hour = Math.floor((date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
              let minutes = Math.floor((date % (1000 * 60 * 60)) / (1000 * 60))
              let seconds = Math.floor((date % (1000 * 60)) / 1000)

              let time = `Tiempo: ${hour}:${minutes}:${seconds}`

              return (
                <div className="winner" key={i}>
                  <h3>{winner.score + " Pts"}</h3>
                  <h3>{winner.user.name}</h3>
                </div>
              )
            })
          : <div className="winner">
              <h4>No hubo ganadores</h4>
            </div>
        }
      </div>
    </div>
  )

  if (state === 6) return (
    <div className='triviaUser6'>
      <div className="triviaCont">
        <h1>{trivia.text}</h1>
        <div className="canvasCont">
          <canvas id="myChart"></canvas>
        </div>
        <div className="optionsCont">
          {
            optionsPoll.map((ele, idx) => {
              return (
                <div className="option" key={idx}>
                  <h2>Opción {idx + 1}:</h2>
                  <p>{ele}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )

  return (
    <div className='triviaUser'>
    </div>
  )
}

export default TriviaUser
