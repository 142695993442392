import './agenda.css'

let Agenda = (props) => {
  return (
    <div className="agendaContainer">
      <div className="titleCont">
        <h2>{props.day}</h2>
      </div>
      <div className="content">
        {
          props.content.map((ele, idx) => {
            return (
              <div className="Cont" key={idx}>
                <div className="square"></div>
                <div className="infoCont">
                  {
                    ele.subtitle &&
                    <h2 className="subtitle">{ele.subtitle}</h2>
                  }
                  <h3 className="title">{ele.title}</h3>
                  {
                    ele.speakers.map((speaker, idx) => {
                      return (
                        <div className="speakerContA" key={idx}>
                          <h2>{speaker.name}</h2>
                          <h3>{speaker.position}</h3>
                        </div>
                      )
                    })
                  }
                  <p className="time">{ele.time}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Agenda
