export default function ManageBar({ connectedUsers, socket }) {

  const changeRole = (user) => {
    socket.emit('changeRole', user)
  }

  const sortedUsers = Object.keys(connectedUsers).sort((key) => {
    return connectedUsers[key].role === 1 ? -1 : 1
  })

  console.log(sortedUsers);
  console.log(Object.keys(connectedUsers));

  return (
    <div className='manageBar'>
      <h1>Manage Bar</h1>
      <div>
        {sortedUsers.map(key => (
          <div className={connectedUsers[key].role ? 'inCall' : 'outCall'} key={`${key} - ${connectedUsers[key].role}`}>
            <p>{connectedUsers[key].name}</p>
            {connectedUsers[key].role ?
              <button onClick={() => changeRole(key)}>&#10060;</button> :
              <button onClick={() => changeRole(key)}>&#9889;</button>}
          </div>
        ))}
      </div>
    </div>
  )
}